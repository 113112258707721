import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import BackgroundImage from "gatsby-background-image"

import {
  useIntl,
  Link,
  FormattedMessage,
  IntlContextConsumer,
} from "gatsby-plugin-intl"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import Contact from "../components/layout"
import SEO from "../components/Seo"
import FullPageImage from "../components/FullPageImage"

import { Controller, Scene } from "react-scrollmagic"

class PageTemplate extends React.Component {
  render() {
    const pages = this.props.data.allMdx.edges

    let page = {}

    pages.map(({ node }) => {
      if (node.frontmatter.lang == this.props.pageContext.language) {
        page = node
      }
    })

    const siteTitle = this.props.data.site.siteMetadata.title
    const shortcodes = { FullPageImage, Contact }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        {page.frontmatter ? (
          <>
            <SEO
              title={page.frontmatter.title}
              description={page.frontmatter.description || page.excerpt}
            />
            <article id="single-page" className={`transition-fade`}>
              <div className="page-header">
                <span className="page-header-title">
                  {page.frontmatter.title}
                </span>
                <span className="page-header-location">
                  {page.frontmatter.location}
                </span>
              </div>

              <div className="page-content">
                {page.frontmatter.description && (
                  <p class="page-content-excerpt">
                    {page.frontmatter.description}
                  </p>
                )}

                <MDXRenderer className="page-content-body">
                  {page.body}
                </MDXRenderer>
              </div>
            </article>
          </>
        ) : (
          <>
            <SEO title="404: Not Found" />
            <h1>Not Found</h1>
            <FormattedMessage id="missing_content" />
          </>
        )}
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          body
          fields {
            slug
          }
          frontmatter {
            title
            description
            lang
          }
        }
      }
    }
  }
`
